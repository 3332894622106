<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import "echarts-gl";
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'

  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      dataY: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      echartData: {
        type: Array,
        default: ()=>{
          return [{value: 0,name: "优"},
                  {value: 0,name: "良"},
                  {value: 0,name: "中"},
                  {value: 1,name: "差"}];
        }
      },
      units:{
        type:String
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'echartData':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
        let sum = this.echartData.reduce((cur, pre) => {
          return cur + pre.value;
        }, 0);
        let data = [];
        let legendData = [];
        var color = ["#03B674", "#D3E001", "#E3A916", "#F92929", "#6f81da", "#00ffb4"];
        for (var i = 0; i < this.echartData.length; i++) {
          let name = this.echartData[i].name + '  ' + this.echartData[i].value+' 件/套';
          legendData.push(name);
          data.push(
              {
                value: this.echartData[i].value,
                name: name,
                itemStyle: {
                    borderWidth: 0,
                    borderRadius: 10,
                    shadowBlur: 10,
                    borderColor: color[i],
                    shadowColor: color[i],
                },
              },
              {
                value: sum / 100, // 控制每个环形之间的间隙
                name: '',
                itemStyle: {
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                    color: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 0,
                },
              }
          );
        }
        this.chart.setOption({
            title: {
                text: sum,
                subtext:'文物总数',
                textAlign: 'center',
                textStyle: {
                    color: '#fff',
                    fontSize: 18,
                },
                subtextStyle: {
                    fontSize: 14,
                    fontWeight: 'bolder',
                    color: '#fff'
                },
                x: '28%',
                y: '38%'
            },
            color: color,
            tooltip: {
                show: false,
            },
            legend: {
                selectedMode:false,
                icon: 'rect',
                itemWidth: 16,
                itemHeight: 8,
                itemStyle: {
                    borderWidth: 3
                },
                orient: 'vertical',
                data: legendData,
                right: '10%',
                top: 'center',
                align: 'left',
                textStyle: {
                    color: '#fff',
                    fontSize: 14,
                    padding: [0, 0, 0, 10]
                },
                itemGap: 20, // 图例之间的间隔
            },
            toolbox: {
                show: false,
            },
            series: [{
                name: '',
                type: 'pie',
                clockwise: false,
                radius: ['50%', '70%'],
                center: ['30%', '50%'],
                emphasis: {
                    scale: false,
                },
                label: {
                    show: false,
                },
                data: data,
            }],
          
        })
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>